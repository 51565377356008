<template>
  <div class="error-config">
    <img
      src="@/assets/images/notfound/error-config-banner.svg"
      alt=""
      class="banner"
    />
    <h1>Configuration Error</h1>
    <h3>Sorry, some config does not exist.</h3>
    <ul class="config-list">
      <strong>Please check</strong>
      <li v-for="(item, index) in apiSetting" :key="index">{{ item }}</li>
    </ul>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'

export default {
  name: 'ConfigAzure',
  mixins: [Mixin],
  data() {
    return {
      apiSetting: null,
    }
  },
  created() {
    window.scrollTo(0, 0)
    this.handleLoading(false)
    this.handleFooter(false)
    console.log('params : ', this.$route)
    if (this.$route) {
      if (this.$route.params) {
        if (Object.keys(this.$route.params).length > 0) {
          if (this.$route.params.APISetting) {
            this.apiSetting = this.$route.params.APISetting
            if (Object.keys(this.$route.params.APISetting).length > 0) {
              this.isApiSetting = true
            } else {
              this.isApiSetting = false
            }
          }
        } else {
          Account.setLogout()
          this.$router.push({ name: 'Login' })
        }
      }
    }
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
@import '@/style/main.scss';
.error-config {
  padding: 40px 20px;
  align-items: center;
  text-align: -webkit-center;
  .banner {
    width: 100%;
    max-width: 650px;
  }
  h1 {
    margin: 16px;
  }
  .config-list {
    max-width: 580px;
    padding: 16px;
    border: 1px solid;
    border-radius: 6px;
    margin: 20px;
    text-align: left;

    li {
      padding-left: 8px;
      margin-left: 32px;
    }
  }
}
</style>
